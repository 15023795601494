































































































































import SCISearch from "@/components/sign/signup/steps/SCISearch.vue";
import { defineComponent, PropType, Ref, ref } from "@vue/composition-api";
import { ProductsModel, TaxRegime } from "@edmp/api";

export default defineComponent({
  name: "RegisterActivity",
  components: { SCISearch },
  props: {
    step: {
      type: String,
      required: true,
    },
    taxRegime: {
      type: String as PropType<TaxRegime>,
      required: false,
    },
    type: {
      type: String as PropType<ProductsModel.Tags>,
      require: false,
    },
  },
  setup(props, context) {
    const activityRegistered: Ref<boolean | undefined> = ref(undefined);
    const validateInProgress: Ref<boolean> = ref(false);

    const validate = async () => {
      validateInProgress.value = true;
      if (props.taxRegime) {
        context.emit(
          activityRegistered.value ? "validate" : "validateNonRegistered"
        );
      }
    };
    return { TaxRegime, activityRegistered, validateInProgress, validate };
  },
});
