import { groupBy, orderBy } from "lodash";
import moment from "moment";
import { Document, Schema, model } from "mongoose";
import { ulid } from "ulid";
import { AccountingBalanceSheet } from "./AccountingBalanceSheet.model";
import { AccountingPeriod } from "./AccountingPeriod.model";
import { OmitField, RequireField } from "./Common.model";
import { ProductsModel } from "./Product.model";
import { TypeReference } from "./JournalComposedEntry";
import { Partner } from "./Partner.model";
import { RealEstateAmortisation } from "./RealEstateAmortisation.model";
import { RealEstateAsset } from "./RealEstateAsset.model";
import { RealEstateLoan } from "./RealEstateLoan.model";
import { SubscriptionsModel } from "./Subscription.model";
import { TaskCode } from "./TaskCode.enum";
import { TaxRegime } from "./TaxRegime.enum";
import { Transaction } from "./Transaction.model";
import { User } from "./User.model";
import { AccountingCarryForward, AccountingCarryForwardLineType } from "./AccountingCarryForward.model";
import { FixedAsset } from "./FixedAsset.model";
import { ActivitiesModel } from "./Activities.model";
import { TaxDeclaration, TaxDeclarationsService } from "./TaxDeclaration.model";

export enum TypeTaskActivity {
  STANDARD = "STANDARD",
  FLOW = "FLOW",
}

export enum TaskActivityStatus {
  PENDING = "pending",
  COMPLETED = "completed",
  WAITING = "waiting",
}

// Type reference specific to Task Activity
enum TypeReferenceTaskActivity {
  user = "user",
  product = "product",
  activity = "activity",
  accountingPeriod = "accountingPeriod",
  assembly = "assembly",
  subscription = "subscription",
}

// Concat types reference
export const TaskActivityTypeReference = {
  ...TypeReference,
  ...TypeReferenceTaskActivity,
};
export type TaskActivityTypeReference = typeof TaskActivityTypeReference[keyof typeof TaskActivityTypeReference];

// Reference specific to Task Activity
export type TaskActivityReference = {
  type: TaskActivityTypeReference;
  referredId: string;
};

export type TaskActivityReferenceDocument = TaskActivityReference & Document<string>;

export const taskActivityReferenceSchema = new Schema<TaskActivityReferenceDocument>({
  _id: { type: String, default: () => ulid() },
  type: {
    type: String,
    required: true,
    enum: Object.values(TaskActivityTypeReference),
  },
  referredId: { type: String, required: true },
});

export type UpdateStatusParams = {
  user?: User;
  product?: ProductsModel.Product;
  partners?: Partner[];
  assets?: RealEstateAsset[];
  realEstateAmortisations?: RealEstateAmortisation[];
  loans?: RealEstateLoan[];
  accountingPeriod?: AccountingPeriod;
  transactionsCategorize?: Transaction[];
  accountingBalanceSheet?: AccountingBalanceSheet;
  accountingCarryForward?: AccountingCarryForward;
  subscription?: SubscriptionsModel.Subscription;
  taxDeclaration?: TaxDeclaration;
};

export type isExcludeParams = {
  taskActivity?: TaskActivity;
  taskActivities?: TaskActivity[];
  subscription?: SubscriptionsModel.Subscription;
};

// To add specific behavior by product
export type TaskActivityBehavior<TypeTask extends TypeTaskActivity> = {
  taxRegime: TaxRegime;
} & (TypeTask extends TypeTaskActivity.FLOW
  ? {
      isBlockedByTaskCodes?: TaskCode[];
      blocksTaskCodes?: TaskCode[];
    }
  : { updateStatus?: (params: UpdateStatusParams) => TaskActivityStatus });

export type TaskActivityMetadata<TypeTask extends TypeTaskActivity> = {
  type: TypeTask;
  typeReferences: TaskActivityTypeReference[];
  // isComingSoon allows abstracting the task within the flow
  isComingSoon?: boolean;
  // The isExclude function allows exclude the task within the flow.
  isExclude?: (params: isExcludeParams) => boolean;
  hasDueDate?: boolean;
  isRecovery?: boolean;
  behaviors: TaskActivityBehavior<TypeTask>[];
};

export type TaskActivity = {
  id: string;
  code: TaskCode;
  references?: TaskActivityReference[];
  status: TaskActivityStatus;
  completedAt?: string;
  canceledAt?: string;
  dueDate?: string;
  openDate?: string;
  createdAt: string;
  updatedAt: string;
  data?: {
    taxRegime?: TaxRegime; //For Task User Notification
  };
} & TaskActivityMetadata<TypeTaskActivity>;

export type TaskActivityCreate = Pick<
  TaskActivity,
  "code" | "references" | "status" | "completedAt" | "dueDate" | "openDate"
>;
export type TaskActivityUpdate = RequireField<
  Partial<Pick<TaskActivity, "id" | "status" | "dueDate" | "openDate" | "data" | "completedAt" | "canceledAt">>,
  "id"
>;

// mongo
export type TaskActivityDocument = TaskActivity & Document<string>;

export const taskActivitySchema = new Schema<TaskActivityDocument>(
  {
    _id: { type: String, default: () => ulid() },
    code: { type: String, enum: Object.values(TaskCode), index: true, required: true },
    references: [taskActivityReferenceSchema],
    status: { type: String, enum: Object.values(TaskActivityStatus) },
    completedAt: { type: String },
    canceledAt: { type: String },
    dueDate: { type: String },
    openDate: { type: String },
    data: {
      type: "object",
      properties: {
        taxRegime: { type: String, enum: Object.values(TaxRegime) },
      },
    },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: TaskActivityDocument) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);

export const TaskActivityModel = model<TaskActivityDocument>("TaskActivity", taskActivitySchema, "TasksActivity");

// API
export namespace TaskActivityService {
  export type ListIn = { productId?: string };
  export type ListOut = TaskActivityGroup[];

  export type ListPaginateIn = { userId: string };
  export type ListPaginateOut = TaskActivityGroup[];

  export type GetIn = {
    groupCode: TaskGroupCode;
    productId?: string;
    accountingPeriodId?: string;
    assemblyId?: string;
  };
  export type GetOut = TaskActivityGroup;

  export type ValidateIn = {
    id: string;
    [TaskCode.FillCerfa2065]?: OmitField<TaxDeclarationsService.ValidateIn<TaxRegime.IS_2065>, "accountingPeriodId">;
    [TaskCode.FillCerfa2031]?: OmitField<TaxDeclarationsService.ValidateIn<TaxRegime.LMNP_2031>, "accountingPeriodId">;
  };
  export type ValidateOut = TaskActivity;

  export type ActivateIn = { id: string };
  export type ActivateOut = TaskActivity;

  export type UpdateNotificationIn = {
    taskCode: TaskCode;
    userIds?: string[];
    status: TaskActivityStatus;
    data?: TaskActivity["data"];
  };
  export type UpdateNotificationOut = { taskActivitiesUpdated: TaskActivity[]; taskActivitiesError: TaskActivity[] };
}

/**
 * Groups
 */

// type returned by controller
export type TaskActivityGroup = {
  groupCode: TaskGroupCode;
  type: TypeTaskActivity;
  status: TaskActivityStatus;
  references: TaskActivityReference[];
  taskActivities: TaskActivity[];
};

export type TaskGroupDefinition = {
  dependencies: TaskCode[];
};

export enum TaskGroupCode {
  StartWell = "start.well",
  StartWellInformation = "start.well.information",
  StartWellAccounting = "start.well.accounting",
  StartWellLMNP = "start.well.LMNP",
  StartWellAccountingLMNP = "start.well.accounting.LMNP",
  AccountingPeriod = "accounting.period",
  Transactions = "transactions",
  TransactionsYearEnd = "transactions.year-end",
  TaxReturnPrepareClose = "tax-return.prepare-close",
  EventGeneralAssembly = "event.general-assembly",
  UserNotifications = "user.notifications",
  TaxReturn2072 = "tax-return.2072",
  TaxReturn20422044 = "tax-return.2042-2044",
  EventAccountingPeriodClosure = "event.accounting-period.closure",
  YearEndTransactionsCheck = "year-end.transaction-check",
  FinancialReporting = "year-end.financial-reporting",
  GetTaxReturns = "year-end.get-tax-returns",
  EventGeneralAssemblyYearEnd = "event.general-assembly.year-end",
  TeletransmitTaxReturn = "teletransmit-tax-return",
  BalanceSheet = "balance-sheet",
}

export type TaskGroups = {
  [GroupCode in TaskGroupCode]: TaskGroupDefinition;
};

export const taskGroups: TaskGroups = {
  [TaskGroupCode.StartWell]: {
    dependencies: [
      TaskCode.EmailAdd,
      TaskCode.EmailValidate,
      TaskCode.SiretAdd,
      TaskCode.PartnerCreate,
      TaskCode.AssetUpdate,
      TaskCode.BankAccountLink,
      TaskCode.BalanceSheetRecovery,
      TaskCode.InjectBalance,
    ],
  },
  [TaskGroupCode.StartWellInformation]: {
    dependencies: [TaskCode.EmailAdd, TaskCode.EmailValidate, TaskCode.SiretAdd],
  },
  [TaskGroupCode.StartWellAccounting]: {
    dependencies: [
      TaskCode.PartnerCreate,
      TaskCode.AssetUpdate,
      TaskCode.BankAccountLink,
      TaskCode.AmortisationUpdate,
      TaskCode.BalanceSheetRecovery,
      TaskCode.InjectBalance,
    ],
  },
  [TaskGroupCode.StartWellLMNP]: {
    dependencies: [
      TaskCode.ActivityInformationCompleted,
      TaskCode.ActivityEmailAdd,
      TaskCode.AssetUpdate,
      TaskCode.AmortisationUpdate,
      TaskCode.LoanAdd,
    ],
  },
  [TaskGroupCode.StartWellAccountingLMNP]: {
    dependencies: [
      TaskCode.DeferrableAmortisationsAdd,
      TaskCode.DeferrableDeficitsAdd,
      TaskCode.BalanceSheetRecovery,
      TaskCode.InjectBalance,
    ],
  },
  [TaskGroupCode.AccountingPeriod]: {
    dependencies: [TaskCode.BalanceSheetRecovery, TaskCode.InjectBalance],
  },
  [TaskGroupCode.Transactions]: {
    dependencies: [TaskCode.FirstTransactionAdd],
  },
  [TaskGroupCode.TransactionsYearEnd]: {
    dependencies: [
      TaskCode.DebitsCreditsRecorded,
      TaskCode.PreviousYearAmortisation,
      TaskCode.TransactionsCategorized,
      TaskCode.SupportingDocuments,
    ],
  },
  [TaskGroupCode.TaxReturnPrepareClose]: {
    dependencies: [
      TaskCode.BuildingConfigured,
      TaskCode.FillCerfa2072,
      TaskCode.UploadCerfa2072,
      TaskCode.CheckPartnersResults,
      TaskCode.SendPartnersResults,
    ],
  },
  [TaskGroupCode.EventGeneralAssemblyYearEnd]: {
    dependencies: [TaskCode.Create, TaskCode.InviteYearEnd, TaskCode.ReportYearEnd],
  },
  [TaskGroupCode.EventGeneralAssembly]: {
    dependencies: [TaskCode.Invite, TaskCode.Report],
  },
  [TaskGroupCode.UserNotifications]: {
    dependencies: [
      TaskCode.SubscriptionActive,
      TaskCode.SubscriptionError,
      TaskCode.ProductUpdate,
      TaskCode.BankCorrect,
      TaskCode.ProductCreate,
      TaskCode.EmailValidate,
    ],
  },
  [TaskGroupCode.EventAccountingPeriodClosure]: {
    dependencies: [
      TaskCode.DebitsCreditsRecorded,
      TaskCode.PreviousYearAmortisation,
      TaskCode.TransactionsCategorized,
      TaskCode.SupportingDocuments,
      TaskCode.BuildingConfigured,
      TaskCode.FillCerfa2072,
      TaskCode.UploadCerfa2072,
      TaskCode.CheckPartnersResults,
      TaskCode.SendPartnersResults,
      TaskCode.Create,
      TaskCode.InviteYearEnd,
      TaskCode.ReportYearEnd,
    ],
  },
  [TaskGroupCode.TaxReturn2072]: {
    dependencies: [TaskCode.BuildingConfigured, TaskCode.FillCerfa2072, TaskCode.UploadCerfa2072],
  },
  [TaskGroupCode.TaxReturn20422044]: {
    dependencies: [TaskCode.CheckPartnersResults, TaskCode.SendPartnersResults],
  },
  [TaskGroupCode.BalanceSheet]: {
    dependencies: [TaskCode.BalanceSheetRecoveryVerify, TaskCode.InjectBalanceVerify, TaskCode.BalanceSimple],
  },
  [TaskGroupCode.YearEndTransactionsCheck]: {
    dependencies: [
      TaskCode.DebitsCreditsRecorded,
      TaskCode.CheckEligibleWorksAreImmobilized,
      TaskCode.TransactionsCategorized,
      TaskCode.SupportingDocuments,
    ],
  },
  [TaskGroupCode.FinancialReporting]: {
    dependencies: [
      TaskCode.BalanceSheetRecoveryVerify,
      TaskCode.YearEndAmortizationUpdate,
      TaskCode.AmortizationsResumptionPostAmortizationsUpdate,
      TaskCode.ViewAndControlResultsAccount,
      TaskCode.ValidateAndObtainBalanceSheet,
      TaskCode.DeferrableAmortisationsAddVerify,
      TaskCode.DeferrableDeficitsAddVerify,
    ],
  },
  [TaskGroupCode.GetTaxReturns]: {
    dependencies: [TaskCode.FillCerfa2033, TaskCode.FillCerfa2065, TaskCode.FillCerfa2031, TaskCode.FillFec],
  },
  [TaskGroupCode.TeletransmitTaxReturn]: {
    dependencies: [TaskCode.TeletransmitMyTaxReturn],
  },
};

const TASK_GROUPE_CODES_CLOSURE: TaskGroupCode[] = [
  TaskGroupCode.TransactionsYearEnd,
  TaskGroupCode.TaxReturnPrepareClose,
  TaskGroupCode.EventGeneralAssemblyYearEnd,
  TaskGroupCode.TeletransmitTaxReturn,
];

export const getTaskGroupCodesClosure = (): TaskGroupCode[] => {
  return TASK_GROUPE_CODES_CLOSURE;
};

export const getTaskGroupDefinition = (taskGroupCode: TaskGroupCode): TaskGroupDefinition => {
  return taskGroups[taskGroupCode];
};

export const getReferredIdByType = (
  taskActivity: TaskActivity,
  typeReference: TaskActivityTypeReference
): string | undefined => {
  return getReferredIdByTypeWithReferences(taskActivity.references, typeReference);
};

export const getReferredIdByTypeWithReferences = (
  references: TaskActivityReference[] | undefined,
  typeReference: TaskActivityTypeReference
): string | undefined => {
  return references?.find((reference) => reference.type === typeReference)?.referredId;
};

export const filterTaskActivitiesByProduct = (taskActivities: TaskActivity[], taxRegime: TaxRegime): TaskActivity[] => {
  return taskActivities.filter(
    (taskActivity: TaskActivity) =>
      taskActivity.behaviors &&
      taskActivity.behaviors.some(
        (behavior: TaskActivityBehavior<typeof taskActivity.type>) => behavior.taxRegime === taxRegime
      )
  );
};

export const getTaskGroupCodesForTaskCode = (taskCode: TaskCode): TaskGroupCode[] => {
  const taskGroupCodes: TaskGroupCode[] = [];
  for (const taskGroupCode of Object.values(TaskGroupCode)) {
    const dependencies: TaskCode[] = taskGroups[taskGroupCode].dependencies;
    if (dependencies.includes(taskCode)) {
      taskGroupCodes.push(taskGroupCode);
    }
  }
  return taskGroupCodes;
};

export const computeGroupStatus = <T extends { status: TaskActivityStatus }>(taskActivityGroup: {
  taskActivities: (T & { isComingSoon?: boolean })[];
}): TaskActivityStatus => {
  if (taskActivityGroup.taskActivities[0]?.status === TaskActivityStatus.WAITING) {
    return TaskActivityStatus.WAITING;
  } else if (
    taskActivityGroup.taskActivities
      .filter((taskActivity) => !taskActivity.isComingSoon)
      .every((taskActivity) => taskActivity.status === TaskActivityStatus.COMPLETED)
  ) {
    return TaskActivityStatus.COMPLETED;
  } else {
    return TaskActivityStatus.PENDING;
  }
};

export const getTaskCodesByTypeReference = (typeReference: TaskActivityTypeReference): TaskCode[] => {
  return Object.keys(taskActivityMetadata)
    .filter((taskCode) => {
      if (taskActivityMetadata.hasOwnProperty(taskCode)) {
        return taskActivityMetadata[taskCode as TaskCode].typeReferences.includes(typeReference);
      }
      return false;
    })
    .map((taskCode) => taskCode as TaskCode);
};

export const isSmallestAccountingPeriod = (
  accountingPeriods: AccountingPeriod[],
  accountingPeriodId: string,
  includesClosed?: boolean
): boolean => {
  let orderedAccountingPeriods;

  if (!includesClosed) {
    orderedAccountingPeriods = orderBy(
      accountingPeriods.filter((accountingPeriod) => !accountingPeriod.closed),
      (accountingPeriod) => {
        return moment(accountingPeriod.startAt).format("YYYYMMDD");
      },
      ["asc"]
    );
  } else {
    orderedAccountingPeriods = orderBy(
      accountingPeriods,
      (accountingPeriod) => {
        return moment(accountingPeriod.startAt).format("YYYYMMDD");
      },
      ["asc"]
    );
  }
  return accountingPeriodId === orderedAccountingPeriods[0].id;
};

/**
 *
 * @param realEstateAsset
 * @param amortizationRecoveryTask
 * @returns true if commissioning date is before or equal amortization recovery date
 */
export const isCommissionedBeforeAmortizationRecovery = <T extends { code: TaskCode; completedAt?: string }>(
  asset: RealEstateAsset | FixedAsset,
  amortizationRecoveryTask: T
): boolean => {
  if (amortizationRecoveryTask.code !== TaskCode.AmortizationsResumptionPostAmortizationsUpdate) {
    return false;
  }
  const commissioningDate = asset.commissioningAt;
  const completedDate = amortizationRecoveryTask.completedAt;
  if (!commissioningDate || !completedDate) {
    return false;
  }
  return new Date(commissioningDate).getTime() <= new Date(completedDate).getTime();
};

export const taskActivityMetadata: { [key in TaskCode]: TaskActivityMetadata<TypeTaskActivity> } = {
  [TaskCode.ProductCreate]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.user],
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus({ user }: { user?: User }) {
          return (user && user.products.length > 0) || (user && user.products.length > 0)
            ? TaskActivityStatus.COMPLETED
            : TaskActivityStatus.PENDING;
        },
      },
      {
        taxRegime: TaxRegime.IS_2065,
        updateStatus({ user }: { user?: User }) {
          return (user && user.products.length > 0) || (user && user.products.length > 0)
            ? TaskActivityStatus.COMPLETED
            : TaskActivityStatus.PENDING;
        },
      },
    ],
  },
  [TaskCode.EmailAdd]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.user, TypeReferenceTaskActivity.product],
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus({ user, product }: { user?: User; product?: ProductsModel.Product }) {
          const isProductCompleted = !!product?.email;
          const isUserCompleted = !!user?.email;
          return isProductCompleted || isUserCompleted ? TaskActivityStatus.COMPLETED : TaskActivityStatus.PENDING;
        },
      },
      {
        taxRegime: TaxRegime.IS_2065,
        updateStatus({ user, product }: { user?: User; product?: ProductsModel.Product }) {
          const isProductCompleted = !!product?.email;
          const isUserCompleted = !!user?.email;
          return isProductCompleted || isUserCompleted ? TaskActivityStatus.COMPLETED : TaskActivityStatus.PENDING;
        },
      },
    ],
  },
  [TaskCode.EmailValidate]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.user, TypeReferenceTaskActivity.product],
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus({ user, product }: { user?: User; product?: ProductsModel.Product }) {
          const isProductCompleted = product?.emailStatus === "confirmed";
          const isUserEmailStatusConfirmed = user?.emailStatus === "confirmed";
          return isProductCompleted || isUserEmailStatusConfirmed
            ? TaskActivityStatus.COMPLETED
            : TaskActivityStatus.PENDING;
        },
      },
      {
        taxRegime: TaxRegime.IS_2065,
        updateStatus({ user, product }: { user?: User; product?: ProductsModel.Product }) {
          const isProductCompleted = product?.emailStatus === "confirmed";
          const isUserEmailStatusConfirmed = user?.emailStatus === "confirmed";
          return isProductCompleted || isUserEmailStatusConfirmed
            ? TaskActivityStatus.COMPLETED
            : TaskActivityStatus.PENDING;
        },
      },
    ],
  },
  [TaskCode.PhoneAdd]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.user],
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus({ user }: { user?: User }) {
          const isUserCompleted = !!user?.phone;
          return isUserCompleted ? TaskActivityStatus.COMPLETED : TaskActivityStatus.PENDING;
        },
      },
      {
        taxRegime: TaxRegime.IS_2065,
        updateStatus({ user }: { user?: User }) {
          const isUserCompleted = !!user?.phone;
          return isUserCompleted ? TaskActivityStatus.COMPLETED : TaskActivityStatus.PENDING;
        },
      },
    ],
  },
  [TaskCode.PhoneValidate]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.user],
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus({ user }: { user?: User }) {
          const isUserCompleted = user?.phoneStatus === "confirmed";
          return isUserCompleted ? TaskActivityStatus.COMPLETED : TaskActivityStatus.PENDING;
        },
      },
      {
        taxRegime: TaxRegime.IS_2065,
        updateStatus({ user }: { user?: User }) {
          const isUserCompleted = user?.phoneStatus === "confirmed";
          return isUserCompleted ? TaskActivityStatus.COMPLETED : TaskActivityStatus.PENDING;
        },
      },
    ],
  },
  [TaskCode.BankCreate]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.user],
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus({ user }: { user?: User }) {
          return !!user?.bankAccounts.length ? TaskActivityStatus.COMPLETED : TaskActivityStatus.PENDING;
        },
      },
      {
        taxRegime: TaxRegime.IS_2065,
        updateStatus({ user }: { user?: User }) {
          return !!user?.bankAccounts.length ? TaskActivityStatus.COMPLETED : TaskActivityStatus.PENDING;
        },
      },
    ],
  },
  [TaskCode.BankCorrect]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.user],
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus({ user }: { user?: User }) {
          return !user?.banks ||
            (!!user?.banks && user?.banks.every((b: { bi_state?: string }) => b.bi_state === "verified"))
            ? TaskActivityStatus.COMPLETED
            : TaskActivityStatus.PENDING;
        },
      },
      {
        taxRegime: TaxRegime.IS_2065,
        updateStatus({ user }: { user?: User }) {
          return !user?.banks ||
            (!!user?.banks && user?.banks.every((b: { bi_state?: string }) => b.bi_state === "verified"))
            ? TaskActivityStatus.COMPLETED
            : TaskActivityStatus.PENDING;
        },
      },
      {
        taxRegime: TaxRegime.LMNP_2031,
        updateStatus({ user }: { user?: User }) {
          return !user?.banks ||
            (!!user?.banks && user?.banks.every((b: { bi_state?: string }) => b.bi_state === "verified"))
            ? TaskActivityStatus.COMPLETED
            : TaskActivityStatus.PENDING;
        },
      },
    ],
  },
  [TaskCode.CreateStatus]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.product],
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus({ product }: { product?: ProductsModel.Product }) {
          return product?.status === ProductsModel.ProductStatus.completed
            ? TaskActivityStatus.COMPLETED
            : TaskActivityStatus.PENDING;
        },
      },
      {
        taxRegime: TaxRegime.IS_2065,
        updateStatus({ product }: { product?: ProductsModel.Product }) {
          return product?.status === ProductsModel.ProductStatus.completed
            ? TaskActivityStatus.COMPLETED
            : TaskActivityStatus.PENDING;
        },
      },
    ],
  },
  [TaskCode.PartnerCreate]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.product],
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus({ partners }: { partners?: Partner[] }) {
          return !!partners && partners.length > 1 ? TaskActivityStatus.COMPLETED : TaskActivityStatus.PENDING;
        },
      },
      {
        taxRegime: TaxRegime.IS_2065,
        updateStatus({ partners }: { partners?: Partner[] }) {
          return !!partners && partners.length > 1 ? TaskActivityStatus.COMPLETED : TaskActivityStatus.PENDING;
        },
      },
    ],
  },
  [TaskCode.SiretAdd]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.product],
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus({ product }: { product?: ProductsModel.Product }) {
          return !!product?.activity.siret ? TaskActivityStatus.COMPLETED : TaskActivityStatus.PENDING;
        },
      },
      {
        taxRegime: TaxRegime.IS_2065,
        updateStatus({ product }: { product?: ProductsModel.Product }) {
          return !!product?.activity.siret ? TaskActivityStatus.COMPLETED : TaskActivityStatus.PENDING;
        },
      },
      {
        taxRegime: TaxRegime.LMNP_2031,
        updateStatus({ product }: { product?: ProductsModel.Product }) {
          return !!product?.activity.siret ? TaskActivityStatus.COMPLETED : TaskActivityStatus.PENDING;
        },
      },
    ],
  },
  [TaskCode.SiretConfirm]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.product],
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus({ product }: { product?: ProductsModel.Product }) {
          return product?.activity.siretStatus === "confirmed"
            ? TaskActivityStatus.COMPLETED
            : TaskActivityStatus.PENDING;
        },
      },
      {
        taxRegime: TaxRegime.IS_2065,
        updateStatus({ product }: { product?: ProductsModel.Product }) {
          return product?.activity.siretStatus === "confirmed"
            ? TaskActivityStatus.COMPLETED
            : TaskActivityStatus.PENDING;
        },
      },
      {
        taxRegime: TaxRegime.LMNP_2031,
        updateStatus({ product }: { product?: ProductsModel.Product }) {
          return product?.activity.siretStatus === "confirmed"
            ? TaskActivityStatus.COMPLETED
            : TaskActivityStatus.PENDING;
        },
      },
    ],
  },
  [TaskCode.BankAccountLink]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.product],
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus({ product }: { product?: ProductsModel.Product }) {
          return !!product?.bankAccounts && product?.bankAccounts.length > 0
            ? TaskActivityStatus.COMPLETED
            : TaskActivityStatus.PENDING;
        },
      },
      {
        taxRegime: TaxRegime.IS_2065,
        updateStatus({ product }: { product?: ProductsModel.Product }) {
          return !!product?.bankAccounts && product?.bankAccounts.length > 0
            ? TaskActivityStatus.COMPLETED
            : TaskActivityStatus.PENDING;
        },
      },
    ],
  },
  [TaskCode.AssetUpdate]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.product],
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus({ assets }: { assets?: RealEstateAsset[] }) {
          return !!assets &&
            assets.some(
              (a) =>
                a.name != null &&
                a != null &&
                a?.address?.zip != null &&
                a?.address?.city != null &&
                a.rentalUnitCount != null &&
                a.nature != null &&
                a.built != null
            )
            ? TaskActivityStatus.COMPLETED
            : TaskActivityStatus.PENDING;
        },
      },
      {
        taxRegime: TaxRegime.IS_2065,
        updateStatus({ assets }: { assets?: RealEstateAsset[] }) {
          return !!assets &&
            assets.some(
              (a) =>
                a.name != null &&
                a != null &&
                a?.address?.zip != null &&
                a?.address?.city != null &&
                a.rentalUnitCount != null &&
                a.nature != null &&
                a.built != null
            )
            ? TaskActivityStatus.COMPLETED
            : TaskActivityStatus.PENDING;
        },
      },
      {
        taxRegime: TaxRegime.LMNP_2031,
        updateStatus({ assets }: { assets?: RealEstateAsset[] }) {
          return !!assets &&
            assets.some(
              (a) =>
                a.name != null &&
                a != null &&
                a?.address?.zip != null &&
                a?.address?.city != null &&
                a.rentalUnitCount != null &&
                a.nature != null &&
                a.built != null
            )
            ? TaskActivityStatus.COMPLETED
            : TaskActivityStatus.PENDING;
        },
      },
    ],
  },
  [TaskCode.AmortisationUpdate]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.product],
    behaviors: [
      {
        taxRegime: TaxRegime.IS_2065,
        updateStatus({
          realEstateAmortisations,
          assets,
        }: {
          realEstateAmortisations?: RealEstateAmortisation[];
          assets?: RealEstateAsset[];
        }) {
          const isCompletedRealEstateAmortisations = !realEstateAmortisations?.length
            ? false
            : !Object.values(groupBy(realEstateAmortisations, "realEstateAssetId")).filter((realEstateAmortisation) => {
                if (!realEstateAmortisation.length) {
                  return true;
                }
                const realEstateAssetIds = assets?.flatMap((realEstateAsset) => realEstateAsset.id);
                return !realEstateAssetIds?.includes(realEstateAmortisation[0].realEstateAssetId);
              }).length;
          return isCompletedRealEstateAmortisations ? TaskActivityStatus.COMPLETED : TaskActivityStatus.PENDING;
        },
      },
      {
        taxRegime: TaxRegime.LMNP_2031,
        updateStatus({
          realEstateAmortisations,
          assets,
        }: {
          realEstateAmortisations?: RealEstateAmortisation[];
          assets?: RealEstateAsset[];
        }) {
          const isCompletedRealEstateAmortisations = !realEstateAmortisations?.length
            ? false
            : !Object.values(groupBy(realEstateAmortisations, "realEstateAssetId")).filter((realEstateAmortisation) => {
                if (!realEstateAmortisation.length) {
                  return true;
                }
                const realEstateAssetIds = assets?.flatMap((realEstateAsset) => realEstateAsset.id);
                return !realEstateAssetIds?.includes(realEstateAmortisation[0].realEstateAssetId);
              }).length;
          return isCompletedRealEstateAmortisations ? TaskActivityStatus.COMPLETED : TaskActivityStatus.PENDING;
        },
      },
    ],
  },

  [TaskCode.BalanceSheetRecovery]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    isExclude({ taskActivities, taskActivity }) {
      const injectBalanceTask = taskActivities?.find((task) => task.code === TaskCode.InjectBalance);

      return (
        taskActivity?.status === TaskActivityStatus.WAITING && injectBalanceTask?.status !== TaskActivityStatus.WAITING
      );
    },
    isRecovery: true,
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus({
          accountingBalanceSheet,
          subscription,
        }: {
          accountingBalanceSheet?: AccountingBalanceSheet;
          subscription?: SubscriptionsModel.Subscription;
        }) {
          if (accountingBalanceSheet?.type === "recovery") {
            if (
              accountingBalanceSheet.isSkipped ||
              (subscription?.plan.type !== SubscriptionsModel.PlanType.Optimum &&
                subscription?.plan.type !== SubscriptionsModel.PlanType.Premium)
            ) {
              return TaskActivityStatus.WAITING;
            } else {
              if (accountingBalanceSheet?.isValidated) {
                return TaskActivityStatus.COMPLETED;
              } else {
                return TaskActivityStatus.PENDING;
              }
            }
          }
          return TaskActivityStatus.WAITING;
        },
      },
      {
        taxRegime: TaxRegime.IS_2065,
        blocksTaskCodes: [TaskCode.YearEndAmortizationUpdate],
        updateStatus({ accountingBalanceSheet }: { accountingBalanceSheet?: AccountingBalanceSheet }) {
          if (accountingBalanceSheet?.type === "recovery") {
            if (accountingBalanceSheet?.isValidated) {
              return TaskActivityStatus.COMPLETED;
            } else {
              return TaskActivityStatus.PENDING;
            }
          }
          return TaskActivityStatus.PENDING;
        },
      },
      {
        taxRegime: TaxRegime.LMNP_2031,
        blocksTaskCodes: [TaskCode.YearEndAmortizationUpdate],
        updateStatus({ accountingBalanceSheet }: { accountingBalanceSheet?: AccountingBalanceSheet }) {
          if (accountingBalanceSheet?.type === "recovery") {
            if (accountingBalanceSheet?.isValidated) {
              return TaskActivityStatus.COMPLETED;
            } else {
              return TaskActivityStatus.PENDING;
            }
          }
          return TaskActivityStatus.PENDING;
        },
      },
    ],
  },
  [TaskCode.InjectBalance]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    isExclude({ taskActivities, taskActivity }) {
      const balanceSheetRecoveryTask = taskActivities?.find((task) => task.code === TaskCode.BalanceSheetRecovery);

      return (
        taskActivity?.status === TaskActivityStatus.WAITING &&
        balanceSheetRecoveryTask?.status !== TaskActivityStatus.WAITING
      );
    },
    isRecovery: true,
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus({
          accountingBalanceSheet,
          subscription,
        }: {
          accountingBalanceSheet?: AccountingBalanceSheet;
          subscription?: SubscriptionsModel.Subscription;
        }) {
          if (accountingBalanceSheet?.type === "recovery") {
            if (
              accountingBalanceSheet.isSkipped ||
              (subscription?.plan.type !== SubscriptionsModel.PlanType.Optimum &&
                subscription?.plan.type !== SubscriptionsModel.PlanType.Premium)
            ) {
              if (
                accountingBalanceSheet.lines.find((line) => {
                  return line.account.startsWith("512000") && line.amount !== 0;
                })
              ) {
                return TaskActivityStatus.COMPLETED;
              } else {
                return TaskActivityStatus.PENDING;
              }
            } else {
              return TaskActivityStatus.WAITING;
            }
          }
          return TaskActivityStatus.WAITING;
        },
      },
    ],
  },
  [TaskCode.DebitsCreditsRecorded]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        blocksTaskCodes: [TaskCode.TransactionsCategorized],
      },
      {
        taxRegime: TaxRegime.IS_2065,
        blocksTaskCodes: [TaskCode.CheckEligibleWorksAreImmobilized],
      },
      {
        taxRegime: TaxRegime.LMNP_2031,
        blocksTaskCodes: [TaskCode.CheckEligibleWorksAreImmobilized],
      },
    ],
  },
  [TaskCode.BalanceSheetRecoveryVerify]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    isExclude({ taskActivity, taskActivities }) {
      return (
        !!taskActivity &&
        !!taskActivities?.some(
          (t) =>
            t.code === TaskCode.BalanceSheetRecovery &&
            getReferredIdByType(t, TaskActivityTypeReference.accountingPeriod) ===
              getReferredIdByType(taskActivity, TaskActivityTypeReference.accountingPeriod) &&
            t.isExclude?.({ taskActivity: t })
        )
      );
    },
    isRecovery: true,
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        blocksTaskCodes: [TaskCode.BalanceSimple],
      },
      {
        taxRegime: TaxRegime.IS_2065,
        blocksTaskCodes: [TaskCode.YearEndAmortizationUpdate],
        isBlockedByTaskCodes: [TaskCode.TransactionsCategorized, TaskCode.SupportingDocuments],
      },
      {
        taxRegime: TaxRegime.LMNP_2031,
        blocksTaskCodes: [TaskCode.YearEndAmortizationUpdate],
        isBlockedByTaskCodes: [TaskCode.TransactionsCategorized, TaskCode.SupportingDocuments],
      },
    ],
  },
  [TaskCode.InjectBalanceVerify]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    isExclude({ taskActivity, taskActivities }) {
      return (
        !!taskActivity &&
        !!taskActivities?.some(
          (t) =>
            t.code === TaskCode.InjectBalance &&
            getReferredIdByType(t, TaskActivityTypeReference.accountingPeriod) ===
              getReferredIdByType(taskActivity, TaskActivityTypeReference.accountingPeriod) &&
            t.isExclude?.({ taskActivity: t })
        )
      );
    },
    isRecovery: true,
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        blocksTaskCodes: [TaskCode.BalanceSimple],
      },
    ],
  },
  [TaskCode.PreviousYearAmortisation]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    isComingSoon: true,
    behaviors: [
      {
        taxRegime: TaxRegime.IS_2065,
      },
    ],
  },

  [TaskCode.TransactionsCategorized]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    hasDueDate: true,
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        isBlockedByTaskCodes: [TaskCode.DebitsCreditsRecorded],
        blocksTaskCodes: [TaskCode.SupportingDocuments],
      },
      {
        taxRegime: TaxRegime.IS_2065,
        isBlockedByTaskCodes: [TaskCode.CheckEligibleWorksAreImmobilized],
        blocksTaskCodes: [TaskCode.BalanceSheetRecoveryVerify],
      },
      {
        taxRegime: TaxRegime.LMNP_2031,
        isBlockedByTaskCodes: [TaskCode.CheckEligibleWorksAreImmobilized],
        blocksTaskCodes: [TaskCode.BalanceSheetRecoveryVerify],
      },
    ],
  },
  [TaskCode.SupportingDocuments]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    hasDueDate: true,
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        isBlockedByTaskCodes: [TaskCode.TransactionsCategorized],
        blocksTaskCodes: [TaskCode.BuildingConfigured],
      },
      {
        taxRegime: TaxRegime.IS_2065,
        isBlockedByTaskCodes: [TaskCode.CheckEligibleWorksAreImmobilized],
        blocksTaskCodes: [TaskCode.BalanceSheetRecoveryVerify],
      },
      {
        taxRegime: TaxRegime.LMNP_2031,
        isBlockedByTaskCodes: [TaskCode.CheckEligibleWorksAreImmobilized],
        blocksTaskCodes: [TaskCode.BalanceSheetRecoveryVerify],
      },
    ],
  },
  [TaskCode.BalanceSimple]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    hasDueDate: true,
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
      },
    ],
  },
  [TaskCode.Invite]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.assembly],
    hasDueDate: true,
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
      },
      {
        taxRegime: TaxRegime.IS_2065,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
      },
    ],
  },
  [TaskCode.Report]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.assembly],
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
      },
      {
        taxRegime: TaxRegime.IS_2065,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
      },
    ],
  },
  [TaskCode.Create]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    hasDueDate: true,
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
        isBlockedByTaskCodes: [TaskCode.SendPartnersResults],
        blocksTaskCodes: [TaskCode.InviteYearEnd],
      },
      {
        taxRegime: TaxRegime.IS_2065,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
        isBlockedByTaskCodes: [TaskCode.FillFec],
        blocksTaskCodes: [TaskCode.InviteYearEnd],
      },
    ],
  },
  [TaskCode.InviteYearEnd]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    hasDueDate: true,
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
        isBlockedByTaskCodes: [TaskCode.Create],
        blocksTaskCodes: [TaskCode.ReportYearEnd],
      },
      {
        taxRegime: TaxRegime.IS_2065,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
        isBlockedByTaskCodes: [TaskCode.Create],
        blocksTaskCodes: [TaskCode.ReportYearEnd],
      },
    ],
  },
  [TaskCode.ReportYearEnd]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
        isBlockedByTaskCodes: [TaskCode.InviteYearEnd],
        blocksTaskCodes: [TaskCode.TeletransmitMyTaxReturn],
      },
      {
        taxRegime: TaxRegime.IS_2065,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
        isBlockedByTaskCodes: [TaskCode.InviteYearEnd],
        blocksTaskCodes: [TaskCode.TeletransmitMyTaxReturn],
      },
      {
        taxRegime: TaxRegime.LMNP_2031,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
        isBlockedByTaskCodes: [TaskCode.InviteYearEnd],
        blocksTaskCodes: [TaskCode.TeletransmitMyTaxReturn],
      },
    ],
  },
  [TaskCode.BuildingConfigured]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    hasDueDate: true,
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
        isBlockedByTaskCodes: [TaskCode.SupportingDocuments],
      },
    ],
  },
  [TaskCode.FillCerfa2072]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    hasDueDate: true,
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
        isBlockedByTaskCodes: [TaskCode.BuildingConfigured],
        blocksTaskCodes: [TaskCode.CheckPartnersResults],
      },
    ],
  },
  [TaskCode.UploadCerfa2072]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    isComingSoon: true,
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
      },
    ],
  },
  [TaskCode.FillCerfa2033]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    hasDueDate: true,
    behaviors: [
      {
        taxRegime: TaxRegime.IS_2065,
        isBlockedByTaskCodes: [TaskCode.ValidateAndObtainBalanceSheet],
        blocksTaskCodes: [],
      },
      {
        taxRegime: TaxRegime.LMNP_2031,
        isBlockedByTaskCodes: [TaskCode.ValidateAndObtainBalanceSheet],
        blocksTaskCodes: [],
      },
    ],
  },
  [TaskCode.FillCerfa2065]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    hasDueDate: true,
    behaviors: [
      {
        taxRegime: TaxRegime.IS_2065,
        isBlockedByTaskCodes: [TaskCode.FillCerfa2033],
        blocksTaskCodes: [TaskCode.FillFec, TaskCode.TeletransmitMyTaxReturn],
      },
    ],
  },
  [TaskCode.FillCerfa2031]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    hasDueDate: true,
    behaviors: [
      {
        taxRegime: TaxRegime.LMNP_2031,
        isBlockedByTaskCodes: [TaskCode.FillCerfa2033],
        blocksTaskCodes: [TaskCode.FillFec, TaskCode.TeletransmitMyTaxReturn],
      },
    ],
  },
  [TaskCode.DeferrableAmortisationsAddVerify]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    hasDueDate: true,
    isRecovery: true,
    behaviors: [
      {
        taxRegime: TaxRegime.LMNP_2031,
        isBlockedByTaskCodes: [TaskCode.SupportingDocuments],
        blocksTaskCodes: [TaskCode.FillCerfa2033],
      },
    ],
  },
  [TaskCode.DeferrableDeficitsAddVerify]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    hasDueDate: true,
    isRecovery: true,
    behaviors: [
      {
        taxRegime: TaxRegime.LMNP_2031,
        isBlockedByTaskCodes: [TaskCode.SupportingDocuments],
        blocksTaskCodes: [TaskCode.FillCerfa2033],
      },
    ],
  },
  [TaskCode.FillFec]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    hasDueDate: true,
    behaviors: [
      {
        taxRegime: TaxRegime.IS_2065,
        isBlockedByTaskCodes: [TaskCode.FillCerfa2065],
        blocksTaskCodes: [TaskCode.Create],
      },
      {
        taxRegime: TaxRegime.LMNP_2031,
        isBlockedByTaskCodes: [TaskCode.FillCerfa2031],
        blocksTaskCodes: [],
      },
    ],
  },

  [TaskCode.CheckPartnersResults]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    hasDueDate: true,
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
        isBlockedByTaskCodes: [TaskCode.FillCerfa2072],
        blocksTaskCodes: [TaskCode.SendPartnersResults],
      },
    ],
  },
  [TaskCode.SendPartnersResults]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    hasDueDate: true,
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
        isBlockedByTaskCodes: [TaskCode.CheckPartnersResults],
        blocksTaskCodes: [TaskCode.Create],
      },
    ],
  },
  [TaskCode.EndingBalanceVerify]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    isComingSoon: true,
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
      },
    ],
  },
  [TaskCode.SubscriptionActive]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.user],
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
      },
      {
        taxRegime: TaxRegime.IS_2065,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
      },
    ],
  },
  [TaskCode.SubscriptionError]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.user],
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
      },
      {
        taxRegime: TaxRegime.IS_2065,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
      },
    ],
  },
  [TaskCode.ProductUpdate]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.user],
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
      },
      {
        taxRegime: TaxRegime.IS_2065,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
      },
      {
        taxRegime: TaxRegime.LMNP_2031,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
      },
    ],
  },
  [TaskCode.ActivityInformationCompleted]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.activity],
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus({ product }: { product?: ProductsModel.Product }) {
          if (product && product.activity.type === ActivitiesModel.ActivityTypes.COMPANY) {
            return product.activity.address &&
              product.activity.address.street &&
              product.activity.address.city &&
              product.activity.address.zip &&
              product.activity.siret
              ? TaskActivityStatus.COMPLETED
              : TaskActivityStatus.PENDING;
          }
          return TaskActivityStatus.PENDING;
        },
      },
      {
        taxRegime: TaxRegime.IS_2065,
        updateStatus({ product }: { product?: ProductsModel.Product }) {
          if (product && product.activity.type === ActivitiesModel.ActivityTypes.COMPANY) {
            return product.activity.address &&
              product.activity.address.street &&
              product.activity.address.city &&
              product.activity.address.zip &&
              product.activity.siret
              ? TaskActivityStatus.COMPLETED
              : TaskActivityStatus.PENDING;
          }
          return TaskActivityStatus.PENDING;
        },
      },
      {
        taxRegime: TaxRegime.LMNP_2031,
        updateStatus({ product }: { product?: ProductsModel.Product }) {
          let cgaValidated = true;
          if (product && product.activity.type === ActivitiesModel.ActivityTypes.OPERATOR) {
            if (product.activity.hasCga && product.activity.cga) {
              cgaValidated = !!(
                product.activity.cga.name &&
                product.activity.cga.address &&
                product.activity.cga.address.street &&
                product.activity.cga.address.city &&
                product.activity.cga.address.zip &&
                product.activity.cga.agreementNumber
              );
            }
            if (product.activity.operatorType === ActivitiesModel.ActivityOperatorTypes.NATURAL_PERSON) {
              return product.activity.firstName &&
                product.activity.lastName &&
                product.activity.phone &&
                product.activity.address &&
                product.activity.address.street &&
                product.activity.address.city &&
                product.activity.address.zip &&
                product.activity.siret &&
                product.activity.birthDate &&
                cgaValidated
                ? TaskActivityStatus.COMPLETED
                : TaskActivityStatus.PENDING;
            }
          }
          return TaskActivityStatus.PENDING;
        },
      },
    ],
  },
  [TaskCode.LoanAdd]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.product],
    behaviors: [
      {
        taxRegime: TaxRegime.LMNP_2031,
        updateStatus({ loans }: { loans?: RealEstateLoan[] }) {
          return loans && loans.length > 0 ? TaskActivityStatus.COMPLETED : TaskActivityStatus.PENDING;
        },
      },
    ],
  },
  [TaskCode.ActivityEmailAdd]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.product],
    behaviors: [
      {
        taxRegime: TaxRegime.LMNP_2031,
        updateStatus({ product }: { product?: ProductsModel.Product }) {
          return product?.email ? TaskActivityStatus.COMPLETED : TaskActivityStatus.PENDING;
        },
      },
    ],
  },
  [TaskCode.FirstTransactionAdd]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.product],
    behaviors: [
      {
        taxRegime: TaxRegime.LMNP_2031,
        updateStatus({ transactionsCategorize }: { transactionsCategorize?: Transaction[] }) {
          if (transactionsCategorize && transactionsCategorize.length > 0) {
            return TaskActivityStatus.COMPLETED;
          } else {
            return TaskActivityStatus.PENDING;
          }
        },
      },
    ],
  },
  [TaskCode.DeferrableAmortisationsAdd]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    isRecovery: true,
    behaviors: [
      {
        taxRegime: TaxRegime.LMNP_2031,
        updateStatus({ accountingCarryForward }: { accountingCarryForward?: AccountingCarryForward }) {
          const totalDepreciation = accountingCarryForward?.lines.find(
            (acf) => acf.type === AccountingCarryForwardLineType.TOTAL_DEPRECIATION
          );
          if (totalDepreciation && totalDepreciation.amount !== -1) {
            return TaskActivityStatus.COMPLETED;
          }
          return TaskActivityStatus.PENDING;
        },
      },
    ],
  },
  [TaskCode.DeferrableDeficitsAdd]: {
    type: TypeTaskActivity.STANDARD,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    isRecovery: true,
    behaviors: [
      {
        taxRegime: TaxRegime.LMNP_2031,
        updateStatus({ accountingCarryForward }: { accountingCarryForward?: AccountingCarryForward }) {
          const totalDeficit = accountingCarryForward?.lines.find(
            (acf) => acf.type === AccountingCarryForwardLineType.TOTAL_DEFICIT
          );
          if (totalDeficit && totalDeficit.amount !== -1) {
            return TaskActivityStatus.COMPLETED;
          }
          return TaskActivityStatus.PENDING;
        },
      },
    ],
  },
  [TaskCode.CheckEligibleWorksAreImmobilized]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    behaviors: [
      {
        taxRegime: TaxRegime.IS_2065,
        isBlockedByTaskCodes: [TaskCode.DebitsCreditsRecorded],
        blocksTaskCodes: [TaskCode.TransactionsCategorized, TaskCode.SupportingDocuments],
      },
      {
        taxRegime: TaxRegime.LMNP_2031,
        isBlockedByTaskCodes: [TaskCode.DebitsCreditsRecorded],
        blocksTaskCodes: [TaskCode.TransactionsCategorized, TaskCode.SupportingDocuments],
      },
    ],
  },
  [TaskCode.YearEndAmortizationUpdate]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    behaviors: [
      {
        taxRegime: TaxRegime.IS_2065,
        isBlockedByTaskCodes: [TaskCode.BalanceSheetRecoveryVerify],
        blocksTaskCodes: [TaskCode.AmortizationsResumptionPostAmortizationsUpdate],
      },
      {
        taxRegime: TaxRegime.LMNP_2031,
        isBlockedByTaskCodes: [TaskCode.BalanceSheetRecoveryVerify],
        blocksTaskCodes: [TaskCode.AmortizationsResumptionPostAmortizationsUpdate],
      },
    ],
  },

  [TaskCode.AmortizationsResumptionPostAmortizationsUpdate]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    isRecovery: true,
    behaviors: [
      {
        taxRegime: TaxRegime.IS_2065,
        isBlockedByTaskCodes: [TaskCode.YearEndAmortizationUpdate],
        blocksTaskCodes: [TaskCode.ViewAndControlResultsAccount],
      },
      {
        taxRegime: TaxRegime.LMNP_2031,
        isBlockedByTaskCodes: [TaskCode.YearEndAmortizationUpdate],
        blocksTaskCodes: [TaskCode.ViewAndControlResultsAccount],
      },
    ],
  },

  [TaskCode.ViewAndControlResultsAccount]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    behaviors: [
      {
        taxRegime: TaxRegime.IS_2065,
        blocksTaskCodes: [TaskCode.ValidateAndObtainBalanceSheet],
        isBlockedByTaskCodes: [TaskCode.AmortizationsResumptionPostAmortizationsUpdate],
      },
      {
        taxRegime: TaxRegime.LMNP_2031,
        blocksTaskCodes: [TaskCode.ValidateAndObtainBalanceSheet],
        isBlockedByTaskCodes: [TaskCode.AmortizationsResumptionPostAmortizationsUpdate],
      },
    ],
  },

  [TaskCode.ValidateAndObtainBalanceSheet]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    behaviors: [
      {
        taxRegime: TaxRegime.IS_2065,
        isBlockedByTaskCodes: [TaskCode.ViewAndControlResultsAccount],
        blocksTaskCodes: [TaskCode.FillCerfa2033],
      },
      {
        taxRegime: TaxRegime.LMNP_2031,
        isBlockedByTaskCodes: [TaskCode.ViewAndControlResultsAccount],
        blocksTaskCodes: [TaskCode.FillCerfa2033],
      },
    ],
  },

  [TaskCode.TeletransmitMyTaxReturn]: {
    type: TypeTaskActivity.FLOW,
    typeReferences: [TypeReferenceTaskActivity.accountingPeriod],
    behaviors: [
      {
        taxRegime: TaxRegime.IR_2072,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
        blocksTaskCodes: [],
        isBlockedByTaskCodes: [TaskCode.FillCerfa2072],
      },
      {
        taxRegime: TaxRegime.IS_2065,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
        blocksTaskCodes: [],
        isBlockedByTaskCodes: [TaskCode.FillCerfa2065],
      },
      {
        taxRegime: TaxRegime.LMNP_2031,
        updateStatus() {
          return TaskActivityStatus.WAITING;
        },
        blocksTaskCodes: [],
        isBlockedByTaskCodes: [TaskCode.FillCerfa2031],
      },
    ],
  },
};
